module.exports = {
    language:'言語',
    More:'もっと',
    title:'中国外国語局のアジア太平洋コミュニケーションセンター ',
    titleP:"中国外国語局のアジア太平洋コミュニケーションセンター（People's China Magazine、China Report Magazine）は、アジア太平洋地域のメディア属性を備えた国際コミュニケーション複合施設です。 センターの主な機能は、「People's China」（日本語）、「China Report」（中国語）、「China ASEAN Report」（英語）、「China」（韓国語）、およびその他の多言語の国際ブティックジャーナルを発行および配布することです。アジア太平洋地域、運営センターは、中国、日本、英語、韓国、エスペラントなどの多言語ウェブサイトと国内外の主流のソーシャルメディアアカウントに属しており、多言語オーディオおよびビデオ製品を製造し、次のような大規模なフォーラムを主催または主催しています。 「北京-東京フォーラム」、「パンダカップ」、「ウコンカップ」など、各国間の文化交流活動。 東京支店、バンコク支店およびその他の海外機関を管理および運営しています。",
    titlePHide:'中国外文局亚太传播中心（CICG Asia-Pacific），是面向亚太地区的具有媒体属性的国际传播综合体。由成立于1950年的人民中国杂志社、中国报道杂志社的全部传播业务及北京周报社日文业务、人民画报社韩文业务整合组建而成。代管中华全国世界语协...',
    Mores:'詳細を見る',
    li1:'チャイナレポート',
    li2:'人々の中国',
    li3:'北京ウィークリー',
    li4:'中国語韓国語',
    li5:'中国アセアンレポート',
    li6:'エスペラントネットワーク',
    li1P:'「チャイナレポート」は1950年に設立されました。これは、中国共産党中央委員会の宣伝部が主導する全国レベルの現在の政治経済中国月刊誌です。定期的に、デジタル変革の中心的なデモンストレーションユニットでもあります。',
    li2P:'「People\'sChina」は、「中国の国家情勢を説明し、人々の友情を高める」という概念に準拠し、中国の声を伝え、中国の物語を語り、中国の文化を広め、中国の政策を解釈し、真の三次元的で包括的な中国を提示します。コミュニケーションと理解の間の架け橋。',
    li3P:'ニューチャイナ初の英語ニュースウィークリー「北京レビュー」（北京レビュー）は、ウェブサイトやフェイスブックなどの海外ソーシャルプラットフォームアカウントを日本語で運営し、日本人の視聴者に中国の真の立体的かつ包括的なイメージを提示します。',
    li4P:'韓国の「中国」は、政治的、経済的、文化的、社会的、生態学的文明などの観点から、鮮やかで感動的な例、スマートで鮮やかな言葉、中国の現在の問題に関する詳細なレポート、および経済的および社会的発展の権威ある分析この現象は、中国と韓国の間の文化交流と人と人との絆を促進し、韓国の読者に真の、三次元的で包括的な中国を提示します。',
    li5P:'中国-ASEAN報告書は、中国-ASEANの交流と協力に焦点を当て、東南アジアの発展に焦点を当てた全国的なメディアマトリックスであり、定期刊行物、ウェブサイト、国内外のソーシャルメディアなどのチャネルを通じて外部に広められています。多言語（主に英語、ASEAN諸国の言語で補足）公開および配布され、現在すべてのASEAN諸国をカバーしており、ASEANが中国の物語を語るための重要なプラットフォームとなっています。',
    li6P:'『チャイナレポート』（エスペラント）誌は1950年5月に創刊され、150を超える国や地域に配布されており、読者から「最も絶妙なエスペラントの出版物」と称賛されており、我が国の外部にとって特別な位置を占めています。コミュニケーション。 2000年に、中国のエスペラントレポートのオンライン版が開始され、世界の190以上の国と地域に中国の経済、社会、生活に関する重要な情報を提供しました。',
    foot1:'私たちに関しては',
    foot2:'お問い合わせ',
    foot3:'広告',
    foot4:'法律顧問',
    foot5:'著作権表示',
    footP1:'Copyright China Foreign Languages Administration Asia-Pacific Communication Center Internet News Information Service License：10120190016 Internet Publishing License：New Network Certificate（King）No。189 Radio and TV Program Production Business License：（King）No。07311 Beijing ICP No. 14043293 No.北京パブリックネットワークセキュリティ：110102000508電子メール：cicgap@foxmail.com法律顧問：北京シャンバン法律事務所Jia Jingwei Shi Xiaoqun',
    footP2:'住所：No.24、Baiwanzhuang Street、Xicheng District、Beijing Tel：010-68995855 / 68995939',
    titleH5:'アジアパシフィックコミュニケーションセンター',
    herf:'クリックしてジャンプ',
    Fp1:'Copyright China Foreign Languages Bureau Asia-Pacific Communication Center Internet News Information Service License：10120190016',
    Fp2:'インターネット公開ライセンス：新しいネットワーク証明書（北京）No。189ラジオおよびテレビ番組の制作および運用ライセンス:(京）No。07311北京ICP No. 14043293北京公共ネットワークセキュリティ：110102000508',
    Fp3:'Eメール：cicgap@foxmail.com法律顧問：北京シャンバン法律事務所JiaJingweiおよびShiXiaoqun',
    Fp4:'住所：No.24、Baiwanzhuang Street、Xicheng District、Beijing Tel：010-68995855 / 68995939',
    DetailsP1:'CICGヨーロッパおよびアジアセンター（CICGヨーロッパ-アジア）（CICGヨーロッパおよびアジアセンター、CICGヨーロッパ-アジア）は、アジア太平洋地域のメディア属性を備えた国際コミュニケーションコンプレックスです。 ピープルズチャイナマガジンとチャイナレポートマガジンのコミュニケーション事業全体、および北京ウィークリーの日本事業とピープルズピクトリアルコリアン事業によって1950年に設立されました。 全中国エスペラント協会を主催する。 センターの主な機能は、「People\'s China」（日本語）、「China Report」（中国語）、「China ASEAN Report」（英語）、「China」（韓国語）、およびその他の多言語の国際ブティックジャーナルを発行および配布することです。アジア太平洋地域。オペレーションセンターは、中国、日本語、英語、韓国語、エスペラント、その他の多言語Webサイト、および国内外の主流のソーシャルメディアアカウントに属しており、多言語オーディオおよびビデオ製品を作成し、「北京・東京フォーラム」、「パンダカップ」、「ウーコン」、「カップ」など、各国間の文化交流活動。 東京支店、バンコク支店、その他の海外機関を管理・運営しています。',
    DetailsP2:`
    電話番号：010-68995855 68995939<p>
     Eメール：cicgap@foxmail.com<p>
     住所：北京西城区白万荘通り24番<p>
    `,
    DetailsP3:'勤務先の電話番号：010-68995855',
    DetailsP4:'',
    DetailsP5:`アジア太平洋コミュニケーションのウェブページに含まれるすべての情報（テキストレポート、写真、音声、ビデオ、チャート、広告、ドメイン名、ソフトウェア、プログラム、レイアウトデザイン、列カタログと名前、コンテンツ分類基準を含むがこれらに限定されない）中国外国語局のセンター著作権は、中国外国語局のアジア太平洋コミュニケーションセンターとデータプロバイダーに帰属します。中国外国語管理のアジア太平洋コミュニケーションセンターの書面による許可なしに、中国外国語のアジア太平洋コミュニケーションセンターの上記のコンテンツを引用、コピー、転載、抽出、または違法に使用することはできません。その他の方法での管理Webサイト。中国外国語局のアジア太平洋コミュニケーションセンターは、上記の行動をとった者に対して法的責任を追求する権利を留保します。<p>

免責事項<p>

1.中国外国語局のアジア太平洋コミュニケーションセンターのロゴと透かしが付いたすべてのテキスト、写真、音声は、「出典：中国外国語局のアジア太平洋コミュニケーションセンター」または「アジア太平洋コミュニケーション」と記されています。このウェブサイトの中国外国語局**テレ/テレコム "ビデオ原稿の著作権は、中国外国語局のアジア太平洋コミュニケーションセンターに帰属します。メディア、ウェブサイト、ユニット、または個人は、このウェブサイトの書面による許可なしに、再印刷、リンク、再投稿、またはその他の方法で複製および公開すること。このウェブサイトによって書面で承認されたメディアおよびウェブサイトは、ダウンロードして使用する際に「原稿の出典：中国外国語管理のアジア太平洋コミュニケーションセンター」を示さなければなりません。そうでない場合は、中国外国語のアジア太平洋コミュニケーションセンター行政は、法律に従って法的責任を追求します。<p>

2.中国外国語局のアジア太平洋コミュニケーションセンターとその新聞および定期刊行物がこのウェブサイトを通じて公開した作品については、このウェブサイトは著作権所有者から委託されており、メディア、ウェブサイト、または個人の転載および使用を禁止しています。書面による許可なしにそれら。<p>

3.このウェブサイトには、「出典：中国外国語管理のアジア太平洋コミュニケーションセンター」または「中国外国語管理テレ/テレコムのアジア太平洋コミュニケーションセンター」またはテキスト、写真、オーディオ、ビデオのマークは付いていません。 LOGOと中国外国語のアジア太平洋コミュニケーションセンターの透かし付き管理すべての再版は、より多くの情報を伝える目的でこのウェブサイトに再版されており、彼らが彼らの見解に同意したり、コンテンツの信憑性を承認したりすることを意味するものではありません。他のメディア、ウェブサイト、または個人がこのウェブサイトからダウンロードして使用する場合、それらはこのウェブサイトに示されている「原稿の出典」を保持する必要があり、著作権およびその他の法的責任を負うものとします。許可なく「原稿の出典：中国外国語局アジア太平洋コミュニケーションセンター」に変更された場合、本ウェブサイトは法律に基づき責任を負います。このウェブサイトに転載された原稿に著作権やその他の問題が含まれている場合は、中国外国語局のアジア太平洋コミュニケーションセンターに電話または書面でお問い合わせください。連絡先番号：+ 8610-68995855。<p>

4.中国外国語局のアジア太平洋コミュニケーションセンターの不正使用による不規則な情報源からの情報の転載、不明確な著作権情報、または中国外国語のアジア太平洋コミュニケーションセンターの名前で公開された情報の不正流用局、このウェブサイトは法律に従って責任を負います。<p>

5.システムのメンテナンスやアップグレードにより、当ウェブサイトのサービスを停止する場合は、事前にお知らせいたします。当ウェブサイトの管理外の配線やハードウェアの故障、その他の不可抗力によりサービスが停止された場合、当ウェブサイトはサービスの停止中に生じたいかなる不便や損失についても責任を負いません。<p>

6.このウェブサイトに掲載されている原稿の範囲が広く、多数あることを考慮して、著者の連絡先情報が不明であるか、その他の理由で著作権所有者に連絡できない場合、著作権所有者がこのウェブサイトが自分の著作物を複製していることを発見した場合、このウェブサイトに連絡するために率先してください。ウェブサイトに連絡し、関連する認証資料を提供してください。そうすれば、私たちのウェブサイトは時間内にそれに対処します。連絡先番号：68995855<p>

7.このウェブサイトの従業員および協力ユニットは、中国外国語局のアジア太平洋コミュニケーションセンターの名前を使用して、許可なく外国の協力、命名、ホスティング、共同組織およびその他の商業活動を行うことはできません。許可なく外国の協力を認めず、規則に違反するユニットまたは個人の法的責任を調査します。<p>

このウェブサイトの声明および修正、更新、最終的な通訳の権利は、中国外国語局のアジア太平洋コミュニケーションセンターに帰属します。<p>

ニュース協力<p>

著者の著作権および情報ネットワークの普及権を尊重し、インターネットの健全な発展を促進するために、このウェブサイトは平等、互恵および資源共有の原則に基づいており、あらゆる種類のメディアに友好的な協力関係を確立するよう心から招待しています。具体的な協力事項については、寄稿サービスを参照するか、+ 8610-68995855までお問い合わせください。<p>
    `,
}