module.exports = {
    language:'언어',
    More:'더',
    title:'중국외국어국 아시아태평양커뮤니케이션센터',
    titleP:"중국외국어국의 아시아태평양 커뮤니케이션 센터(People's China Magazine, China Report Magazine)는 아시아태평양 지역의 미디어 속성을 가진 국제 커뮤니케이션 단지입니다. 센터의 주요 기능은 'Peoples China'(일본어), 'China Report'(중국어), 'China ASEAN Report'(영어), 'China'(한국어) 및 기타 다국어 국제 부티크 저널을 출판 및 배포하는 것입니다. 아시아 태평양 지역에서 운영하는 센터는 중국어, 일본어, 영어, 한국어, 에스페란토어 및 기타 다국어 웹사이트 및 국내외 주류 소셜 미디어 계정에 속해 있으며 다국어 오디오 및 비디오 제품을 생산하고 다음과 같은 대규모 포럼을 주최하거나 주최합니다. 'Beijing-Tokyo Forum', 'Panda Cup' 및 'Wukong Cup' '및 기타 국가 간의 문화 교류 활동. 도쿄 지점, 방콕 지점 및 기타 해외 기관을 관리 및 운영합니다.",
    titlePHide:'中国外文局亚太传播中心（CICG Asia-Pacific），是面向亚太地区的具有媒体属性的国际传播综合体。由成立于1950年的人民中国杂志社、中国报道杂志社的全部传播业务及北京周报社日文业务、人民画报社韩文业务整合组建而成。代管中华全国世界语协...',
    Mores:'자세히보다',
    li1:'중국 보고서',
    li2:'인민의 중국',
    li3:'베이징 위클리',
    li4:'중국어 한국어',
    li5:'중국 아세안 보고서',
    li6:'에스페란토 네트워크',
    li1P:'"중국 보고서"는 1950년에 창간되었으며 중국 공산당 중앙위원회 선전부가 영도하는 국가 차원의 현 정치 및 경제 중국 월간지입니다.',
    li2P:'"인민의 중국"은 "중국의 국가 상황을 설명하고 인민의 우호를 증진한다"는 개념을 고수하고 중국의 목소리를 전달하고 중국 이야기를 전하며 중국 문화를 전파하고 중국 정책을 해석하며 참되고 입체적이며 포괄적인 중국을 제시합니다. 소통과 이해 사이의 다리.',
    li3P:'New China의 첫 영어 뉴스 주간지 "Beijing Review"(Beijing Review)는 웹사이트와 Facebook 및 기타 해외 소셜 플랫폼 계정을 일본어로 운영하면서 일본 시청자들에게 중국에 대한 사실적이고 입체적이며 종합적인 이미지를 제공합니다.',
    li4P:'한국의 "중국"은 생생하고 감동적인 예, 똑똑하고 생생한 언어, 중국 시사 핫스팟에 대한 심층 보고서, 정치, 경제, 문화, 사회, 생태 문명 및 기타 관점에서 중국 발전의 생생한 실천에 의존합니다. 경제 및 사회 발전에 대한 권위 있는 분석 이 현상은 중국과 한국 간의 문화 교류와 인적 유대를 촉진하고 한국 독자들에게 진정한 3차원적이고 포괄적인 중국을 제시합니다.',
    li5P:'China-ASEAN Report는 중국-ASEAN 교류와 협력에 중점을 두고 동남아 발전에 중점을 둔 국가 미디어 매트릭스로, 정기간행물, 웹사이트, 국내외 소셜 미디어 및 기타 채널을 통해 외부 세계에 전파되고 있으며, 다국어 (주로 영어, ASEAN 국가의 언어로 보완) 출판 및 배포되었으며 현재 모든 ASEAN 국가를 포괄하며 ASEAN이 중국 이야기를 전하는 중요한 플랫폼입니다.',
    li6P:'"China Report"(에스페란토) 잡지는 1950년 5월에 창간되어 150개 이상의 국가와 지역에 배포되었습니다. 독자들에게 "가장 정교한 에스페란토 출판물"로 칭송받으며, 우리나라 대외적으로는 특별한 위치입니다. 의사 소통. 2000년에는 중국 에스페란토 보고서의 온라인 버전이 출시되어 중국의 경제, 사회, 생활에 대한 중요한 정보를 전 세계 190개 이상의 국가와 지역에 제공했습니다.',
    foot1:'우리에 대해',
    foot2:'문의하기',
    foot3:'광고하는',
    foot4:'법률 고문',
    foot5:'저작권 고지',
    footP1:'저작권 중국 외국어 관리 아시아 태평양 커뮤니케이션 센터 인터넷 뉴스 정보 서비스 라이센스: 10120190016 인터넷 출판 라이센스: 새로운 네트워크 인증서(京) No.189 라디오 및 TV 프로그램 제작 비즈니스 라이센스: (京) No. 07311 Beijing ICP No. 14043293 번호 Beijing Public Network Security: 110102000508 이메일: cicgap@foxmail.com 법률 고문: Beijing Shanbang Law Firm Jia Jingwei Shi Xiaoqun',
    footP2:'주소: 베이징 시성구 바이완좡가 24호 전화: 010-68995855/68995939',
    titleH5:'아시아 태평양 커뮤니케이션 센터',
    herf:'점프하려면 클릭',
    Fp1:'Copyright 중국외국어국 아시아태평양 커뮤니케이션 센터 인터넷 뉴스 정보 서비스 라이센스: 10120190016',
    Fp2:'인터넷 출판 라이선스: New Network Certificate(Beijing) No. 189 라디오 및 TV 프로그램 제작 및 운영 라이선스: (京) No. 07311 Beijing ICP No. 14043293 Beijing Public Network Security: 110102000508',
    Fp3:'이메일: cicgap@foxmail.com 법률 고문: Beijing Shanbang Law Firm Jia Jingwei 및 Shi Xiaoqun',
    Fp4:'주소: 베이징 시성구 바이완좡가 24호 전화: 010-68995855/68995939',
    DetailsP1:'CICG 유럽 및 아시아 센터(CICG 유럽-아시아)(CICG 유럽 및 아시아 센터, CICG 유럽-아시아)는 아시아 태평양 지역의 미디어 속성을 갖춘 국제 커뮤니케이션 컴플렉스입니다. 1950년 People\'s China Magazine과 China Report Magazine의 전체 커뮤니케이션 비즈니스와 Beijing Weekly의 일본 비즈니스, People\'s Pictorial Korean 비즈니스가 함께 설립했습니다. 전중국 에스페란토 협회를 주최합니다. 센터의 주요 기능은 "People\'s China"(일본어), "China Report"(중국어), "China ASEAN Report"(영어), "China"(한국어) 및 기타 다국어 국제 부티크 저널을 출판 및 배포하는 것입니다. 아시아 태평양 지역. 운영 센터는 중국어, 일본어, 영어, 한국어, 에스페란토어 및 기타 다국어 웹 사이트 및 국내외 주류 소셜 미디어 계정에 속해 있으며 다국어 오디오 및 비디오 제품을 생산하고 "와 같은 대규모 포럼을 주최하거나 주최합니다. Beijing-Tokyo Forum\', "Panda Cup" 및 "Wukong" "Cup" 및 기타 국가 간의 문화 교류 활동. 도쿄 지점, 방콕 지점 및 기타 해외 기관을 관리 및 운영합니다.',
    DetailsP2:`
    전화: 010-68995855 68995939<p>
    이메일: cicgap@foxmail.com<p>
    주소: 베이징 시청구 바이완좡가 24호<p>
    `,
    DetailsP3:'회사 전화: 010-68995855',
    DetailsP4:'',
    DetailsP5:`Asia-Pacific Communication의 웹페이지에 포함된 모든 정보(텍스트 보고서, 그림, 사운드, 비디오, 차트, 광고, 도메인 이름, 소프트웨어, 프로그램, 레이아웃 디자인, 열 카탈로그 및 이름, 콘텐츠 분류 기준을 포함하되 이에 국한되지 않음) 중국외국어국 센터 저작권은 중국외국어국 아시아태평양 커뮤니케이션 센터와 데이터 제공자에게 있습니다. 중국외국어관리국 아시아태평양 커뮤니케이션센터의 서면 허가 없이는 누구도 중국외국어아시아태평양 커뮤니케이션센터의 위 내용을 인용, 복사, 전재, 발췌 또는 불법적으로 사용할 수 없습니다. 다른 방법으로 관리 웹사이트. 중국외국어국 아시아태평양커뮤니케이션센터는 위와 같은 행위를 한 자에 대해 법적 책임을 물을 권리를 보유합니다.<p>

    부인 성명<p>
    
    1. "출처: 중국외국어국 아시아태평양커뮤니케이션센터" 또는 "아시아태평양커뮤니케이션센터"로 표시된 중국외국어국 아시아태평양 커뮤니케이션 센터 로고 및 워터마크가 있는 모든 텍스트, 그림 및 오디오 본 웹사이트의 중국외국어국** Tele/Telecom 센터'에서 동영상 원고의 저작권은 중국외국어국 아시아태평양 커뮤니케이션 센터에 있습니다. 모든 미디어, 웹사이트, 단위 또는 개인은 다음과 같은 행위를 할 수 없습니다. 이 웹사이트의 서면 승인 없이 재인쇄, 링크, 재게시 또는 복제 및 게시하는 행위. 본 웹사이트의 서면 승인을 받은 미디어 및 웹사이트는 중국외국어아시아태평양 커뮤니케이션센터 다운로드 및 사용 시 반드시 "원고 출처: 중국외국어관리국 아시아태평양 커뮤니케이션 센터"라고 명시해야 합니다. 행정은 법에 따라 법적 책임을 추궁할 것입니다.<p>
    
    2. 중국외국어국 아시아태평양커뮤니케이션센터와 그 신문, 정기간행물이 이 웹사이트를 통해 출판된 저작물에 대해, 이 웹사이트는 저작권 소유자로부터 미디어, 웹사이트 또는 개인의 전재 및 사용을 금지하는 권한을 위임받았습니다. 서면 승인 없이.<p>
    
    3. 이 웹사이트는 "출처: 중국외국어국 아시아태평양 커뮤니케이션 센터" 또는 "중국외국어국 아시아태평양 커뮤니케이션 센터 텔레/텔레콤" 또는 텍스트, 사진, 오디오 및 비디오 표시가 없습니다. 중국 외국어 관리국 아시아태평양 커뮤니케이션 센터의 로고 및 워터마크 포함 모든 증쇄본은 더 많은 정보를 전달하기 위해 이 웹사이트에 증쇄되며, 이는 그들이 자신의 견해에 동의하거나 내용의 진정성을 승인한다는 의미가 아닙니다. . 다른 매체, 웹사이트 또는 개인이 이 웹사이트에서 다운로드하여 사용하는 경우 이 웹사이트에 표시된 "원고의 출처"를 유지해야 하며 저작권 및 기타 법적 책임을 져야 합니다. 무단으로 "원고 출처: 중국외국어국 아시아태평양 커뮤니케이션센터"로 변경한 경우, 본 사이트는 법에 따라 책임을 집니다. 본 웹사이트의 전재 원고에 저작권 및 기타 문제가 있는 경우 중국외국어국 아시아태평양 커뮤니케이션 센터로 전화 또는 서신을 보내주시기 바랍니다. 연락처: +8610-68995855.<p>
    
    4. 중국외국어국 아시아태평양커뮤니케이션센터를 무단으로 사용하여 출처가 불분명하거나 저작권 정보가 불명확하거나 중국외국어아시아태평양커뮤니케이션센터 명의로 출판된 정보를 도용하는 행위 국, 이 웹사이트는 법률에 따라 책임을 집니다.<p>
    
    5. 본 웹사이트의 서비스가 시스템 유지보수 또는 업그레이드 등의 사유로 중단되는 경우에는 사전에 공지합니다. 본 웹사이트가 통제할 수 없는 배선 및 하드웨어 장애 또는 기타 불가항력으로 인해 서비스가 중단된 경우, 서비스 중단으로 인한 불편 및 손실에 대해 본 웹사이트는 책임을 지지 않습니다.<p>
    
    6. 본 웹사이트에 게재되어 있는 원고의 범위와 수에 비추어 볼 때, 저자의 연락처를 알 수 없거나 기타 사유로 저작권자에게 연락할 수 없는 경우, 본 사이트가 자신의 저작물을 복제한 것을 저작권자가 발견한 경우, 이 웹사이트에 먼저 연락해 주십시오.웹사이트에 연락하여 관련 인증 자료를 제공하면 당사 웹사이트에서 적시에 처리합니다. 연락처: 68995855<p>
    
    7. 본 웹사이트의 직원 및 협력단위는 허가 없이 중국외국어국 아시아태평양 커뮤니케이션 센터의 이름을 사용하여 대외 협력, 명명, 호스팅, 공동 조직 및 기타 상업 활동을 수행할 수 없습니다. 허가 없이 외국 협력을 인정하지 않으며 규정을 위반한 단위 또는 개인의 법적 책임을 조사합니다.<p>
    
    이 웹사이트의 진술과 수정, 업데이트 및 최종 해석에 대한 권리는 중국외국어국 아시아태평양 커뮤니케이션 센터에 귀속됩니다.<p>
    
    뉴스 협력<p>
    
    저자의 저작권 및 정보 네트워크 보급권을 존중하고 인터넷의 건전한 발전을 촉진하기 위해 이 웹 사이트는 평등, 호혜 및 자원 공유의 원칙에 기초하고 모든 종류의 미디어를 진심으로 초대하여 우호적인 협력 관계를 구축합니다. 구체적인 협력 사항은 공헌도를 참고하거나 상담전화(0610-68995855)로 문의하면 된다.<p>
    `,
}