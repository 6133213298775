<template>
  <div id="app" class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style lang="scss">
.app{
    min-width: 1240px;
    height: 100%;
}
// 默认样式
input[type="search"]::-webkit-search-cancel-button{
    display: none;
}
input[type="search"]{
    -webkit-appearance: none;
}
body,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
dl,
dt,
dd {
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #333;
}

a:hover {
    color: #333;
}

img {
    border: 0 none;
    vertical-align: middle;
}

.centre {
    height: 100%;
}

input,
textarea {
    outline: none;
    border: 0 none;
}

body {
    font: 14px/1.5 arial, 'microsoft yahei';
    color: #333;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

i,
em {
    font-style: normal;
}


</style>
