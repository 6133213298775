module.exports = {
    language:'language',
    More:'More',
    title:'Asia-Pacific Communication Center of China Foreign Languages Bureau',
    titleP:'The Asia-Pacific Communication Center of the China Foreign Languages Bureau (People\'s China Magazine, China Report Magazine) is an international communication complex with media attributes for the Asia-Pacific region. The main function of the center is to publish and distribute "People\'s China" (Japanese), "China Report" (Chinese), "China ASEAN Report" (English), "China" (Korean) and other multilingual international boutique journals for the Asia-Pacific region, and operate The center belongs to Chinese, Japanese, English, Korean, Esperanto and other multilingual websites and mainstream social media accounts at home and abroad, producing multilingual audio and video products, hosting or hosting large-scale forums such as "Beijing-Tokyo Forum", "Panda Cup" and "Wukong Cup" "And other cultural exchange activities between countries. Manage and operate Tokyo branch, Bangkok branch and other overseas institutions.',
    titlePHide:'中国外文局亚太传播中心（CICG Asia-Pacific），是面向亚太地区的具有媒体属性的国际传播综合体。由成立于1950年的人民中国杂志社、中国报道杂志社的全部传播业务及北京周报社日文业务、人民画报社韩文业务整合组建而成。代管中华全国世界语协...',
    Mores:'see details',
    li1:'China Report',
    li2:'People\'s China',
    li3:'Beijing Weekly',
    li4:'Chinese Korean',
    li5:'China Asean Report',
    li6:'Esperanto Network',
    li1P:'"China Report" was founded in 1950. It is a national-level current political and economic Chinese monthly magazine under the leadership of the Propaganda Department of the Central Committee of the Communist Party of China. Periodicals are also the central digital transformation demonstration unit.',
    li2P:'"People\'s China" adheres to the concept of "explaining China\'s national conditions and enhancing people\'s friendship", delivering Chinese voices, telling Chinese stories, disseminating Chinese culture, and interpreting Chinese policies to Japanese people, presenting a true, three-dimensional, and comprehensive China. Build a bridge between communication and understanding.',
    li3P:'New China\'s first English-language news weekly, "Beijing Review" (Beijing Review), while operating websites and Facebook and other overseas social platform accounts in Japanese, presents a true, three-dimensional, and comprehensive image of China to Japanese audiences.',
    li4P:'Korean "China" relies on the vivid practice of China\'s development, from political, economic, cultural, social, ecological civilization and other perspectives, with vivid and moving examples, smart and vivid language, in-depth reports on China\'s current affairs hotspots, and authoritative analysis of economic and social development This phenomenon promotes cultural exchanges and people-to-people bonds between China and South Korea, and presents a true, three-dimensional and comprehensive China to Korean readers.',
    li5P:'China-ASEAN Report is a national media matrix focusing on China-ASEAN exchanges and cooperation, and focusing on the development of Southeast Asia. It is disseminated to the outside world through periodicals, websites, domestic and foreign social media and other channels, and is multilingual (mainly English, supplemented by languages of ASEAN countries) Published and distributed, it now covers all ASEAN countries and is an important platform for ASEAN to tell the story of China.',
    li6P:'"China Report" (Esperanto) magazine was founded in May 1950, and has been distributed to more than 150 countries and regions. It is praised by readers as "the most exquisite Esperanto publication", and it is a special position for my country\'s external communication. In 2000, the online edition of Esperanto of China Report was launched, providing important information about China\'s economy, society, and life to more than 190 countries and regions in the world.',
    foot1:'about us',
    foot2:'contact us',
    foot3:'advertising',
    foot4:'Legal counsel',
    foot5:'Copyright Notice',
    footP1:'Copyright China Foreign Languages Administration Asia-Pacific Communication Center Internet News Information Service License: 10120190016 Internet Publishing License: New Network Certificate (京) No. 189 Radio and TV Program Production Business License: (京) No. 07311 Beijing ICP No. 14043293 No. Beijing Public Network Security: 110102000508 E-mail: cicgap@foxmail.com Legal Counsel: Beijing Shanbang Law Firm Jia Jingwei Shi Xiaoqun',
    footP2:'Address: No. 24, Baiwanzhuang Street, Xicheng District, Beijing Tel: 010-68995855/68995939',
    titleH5:'Asia Pacific Communication Center',
    herf:'Click to jump',
    Fp1:'Copyright China Report Magazine. Radio and TV program production and operation license: (Beijing)',
    Fp2:'Zi No. 07311 Tel: 010-68995855/68995939',
    Fp3:'Internet Publishing License: New Internet License (Beijing) No. 189 Beijing ICP No. 08103425',
    Fp4:'Beijing Public Network Security: 110102000508',
    DetailsP1:'CICG Center for Europe and Asia (CICG Europe-Asia) (CICG Center for Europe and Asia, CICG Europe-Asia) is an international communication complex with media attributes for the Asia-Pacific region. Established in 1950 by the People\'s China Magazine and China Report Magazine\'s entire communication business, as well as the Japanese business of Beijing Weekly and People\'s Pictorial Korean business. Host the All-China Esperanto Association. The main functions of the center are: publishing "People\'s China" (Japanese), "China Report" (Chinese), "China ASEAN Report" (English), "China" (Korean) and other multilingual international boutique journals for the Asia-Pacific region, The operation center belongs to Chinese, Japanese, English, Korean, Esperanto and other multilingual websites and mainstream social media accounts at home and abroad, producing multilingual audio and video products, hosting or hosting large-scale forums such as "Beijing-Tokyo Forum", "Panda Cup" and "Wukong" "Cup" and other cultural exchange activities between countries. Manage and operate Tokyo branch, Bangkok branch and other overseas institutions.',
    DetailsP2:`
    Phone: 010-68995855 68995939<p>
    Email: cicgap@foxmail.com<p>
    Address: No. 24, Baiwanzhuang Street, Xicheng District, Beijing<p>
    `,
    DetailsP3:'Business phone: 010-68995855',
    DetailsP4:'',
    DetailsP5:`Any information (including but not limited to text reports, pictures, sounds, videos, charts, advertisements, domain names, software, programs, layout design, column catalogues and names, content classification standards) involved in the webpages of the Asia Pacific Communication Center of the China Foreign Languages Bureau The copyright belongs to the Asia-Pacific Communication Center of China Foreign Languages Bureau and the data provider. Without the written permission of the Asia-Pacific Communication Center of the China Foreign Languages Administration, no one may quote, copy, reprint, extract, or illegally use the above content of the Asia-Pacific Communication Center of the China Foreign Languages Administration website in any other way. The Asia-Pacific Communication Center of China Foreign Languages Bureau reserves the right to pursue legal responsibilities for those who have committed the above-mentioned actions.<p>
   
    Disclaimer<p>
    1. All texts, pictures and audios with the logo and watermark of the Asia-Pacific Communication Center of China Foreign Languages Bureau marked "Source: Asia-Pacific Communication Center of China Foreign Languages Bureau" or "Asia-Pacific Communication Center of China Foreign Languages Bureau** Tele/Telecom" on this website The copyright of the video manuscript belongs to the Asia-Pacific Communication Center of the China Foreign Languages Bureau. Any media, website, unit or individual may not reprint, link, repost or otherwise reproduce and publish without the written authorization of this website. Media and websites that have been authorized in writing by this website must indicate "Source of the manuscript: Asia-Pacific Communication Center of China Foreign Languages Administration" when downloading and using, otherwise, the Asia-Pacific Communication Center of China Foreign Languages Administration will pursue their legal liabilities in accordance with the law.<p>
    
    2. For the works published by the Asia-Pacific Communication Center of the China Foreign Languages Bureau and its newspapers and periodicals through this website, this website is entrusted by the copyright owner to prohibit any media, website or individual from reprinting and using them without written authorization.<p>
    
    3. This website is not marked with "Source: Asia-Pacific Communication Center of China Foreign Languages Administration" or "Asia-Pacific Communication Center of China International Languages Administration/Telecom" or texts, pictures, audio and video with LOGO and watermark of Asia-Pacific Communication Center of China Foreign Languages Administration All reprints are reprinted on this website for the purpose of conveying more information, and it does not mean that they agree with their views or approve the authenticity of their content. If other media, websites or individuals download and use from this website, they must retain the "source of the manuscript" indicated on this website, and they shall bear the copyright and other legal responsibilities. If it is changed to "Source of the manuscript: Asia-Pacific Communication Center of China Foreign Languages Bureau" without authorization, this website will be held accountable in accordance with the law. If the reprinted manuscripts on this website involve copyright and other issues, please call or write to the Asia-Pacific Communication Center of the China Foreign Languages Bureau. Contact number: +8610-68995855.<p>
    
    4. Unauthorized use of the Asia-Pacific Communication Center of the China Foreign Languages Bureau to reprint information from irregular sources, unclear copyright information, or misappropriation of information published in the name of the Asia-Pacific Communication Center of the China Foreign Languages Bureau, this website will be held accountable in accordance with the law.<p>
    
    5. If the service of this website needs to be suspended due to system maintenance or upgrade, it will be announced in advance. If the service is suspended due to wiring and hardware failures outside the control of this website or other force majeure, this website will not be responsible for any inconvenience and loss caused during the suspension of the service.<p>
    
    6. In view of the wide range and large number of manuscripts published on this website, if the author’s contact information is unknown or other reasons are unable to contact the copyright owner, if the copyright owner discovers that this website has reproduced his copyrighted works, please take the initiative to contact this website. Contact the website and provide relevant certification materials, and our website will deal with it in time. Contact number: 68995855<p>
    
    7. Any employee and cooperative unit of this website shall not use the name of the Asia-Pacific Communication Center of the China Foreign Language Bureau to carry out foreign cooperation, naming, hosting, co-organization and other commercial activities without authorization; our website will not recognize any foreign cooperation without authorization and will Investigate the legal responsibilities of the units or individuals who violate the regulations.<p>
    
    The statement of this website and its right to modify, update and final interpretation belong to the Asia-Pacific Communication Center of the China Foreign Languages Bureau.<p>
    
    News Cooperation<p>
    
    In order to respect the author’s copyright and information network dissemination rights, and promote the healthy development of the Internet, this website is based on the principles of equality, reciprocity, and resource sharing, and sincerely invites all kinds of media to establish friendly cooperative relations with us. For specific cooperation matters, please refer to the contribution service or call for consultation: +8610-68995855.<p>
    `,
}