import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// i18n 实现多语言
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// i18n 配置
const i18n = new VueI18n({
  locale: localStorage.getItem("lang")?localStorage.getItem("lang"):'cn', // 语言标识  
  messages: {
      'cn': require('./assets/i18n/zh'),
      'uk': require('./assets/i18n/en'),
      'jp': require('./assets/i18n/jp'),
      'kr': require('./assets/i18n/kr'),
  },
  silentTranslationWarn: true,
})

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
Vue.config.productionTip = false


new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
