import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [

  {path: '/', 
  name: 'index',
  meta: {title:'亚太传播中心'}, 
  component: resolve => (require(["@/views/index"], resolve)),},//首页
  {path: '/Details', 
  name: 'Details',
  meta: {title:'亚太传播中心'}, 
  component: resolve => (require(["@/views/Details"], resolve)),},//首页
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    if(to.meta.title){
        window.document.title = to.meta.title
    } else {
        next()
    }
    next()
})

export default router
