module.exports = {
    language:'语言',
    More:'更多',
    title:'中国外文局亚太传播中心',
    titleP:'中国外文局亚太传播中心（CICG Asia-Pacific），是面向亚太地区的具有媒体属性的国际传播综合体。由成立于1950年的人民中国杂志社、中国报道杂志社的全部传播业务及北京周报社日文业务、人民画报社韩文业务整合组建而成。代管中华全国世界语协会。中心的主要职能是：面向亚太地区出版发行《人民中国》（日文）、《中国报道》（中文）、《中国东盟报道》（英文）、《中国》（韩文）等多语种国际化精品期刊，运营中心所属中文、日文、英文、韩文、世界语等多语种网站和国内外主流社交媒体账号，制作多语种音视频产品，主办或承办“北京-东京论坛”等大型论坛、“熊猫杯”“悟空杯”等国家间文化交流活动。管理运营东京分社、曼谷分社等驻外机构。',
    titlePHide:'中国外文局亚太传播中心（CICG Asia-Pacific），是面向亚太地区的具有媒体属性的国际传播综合体。由成立于1950年的人民中国杂志社、中国报道杂志社的全部传播业务及北京周报社日文业务、人民画报社韩文业务整合组建而成。代管中华全国...',
    Mores:'查看详情',
    li1:'中国报道',
    li2:'人民中国',
    li3:'北京周报',
    li4:'中国韩文',
    li5:'中国东盟报道',
    li6:'世界语网',
    li1_tit:'中国报道(中文)',
    li2_tit:'人民中国(日文)',
    li3_tit:'北京周报(日文)',
    li4_tit:'中国(韩文)',
    li5_tit:'中国东盟报道(英文)',
    li6_tit:'中国报道世界语网',
    li1P:'《中国报道》创刊于1950年，是由中共中央宣传部领导，国务院新闻办、中央直属对外宣传机构中国外文局主管的国家级时政财经类中文月刊，被中共中央宣传部列为政经类重点期刊，也是中央数字化转型示范单位。',
    li2P:'《人民中国》秉承“说明中国国情，增进人民友好”的理念，向日本民众传递中国声音、讲述中国故事、传播中华文化、解读中国政策，展现一个真实、立体、全面的中国，在中日之间架起一座沟通与理解的桥梁。',
    li3P:'新中国第一本英文新闻时政周刊《北京周报》（Beijing Review），同时以日文运营网站和Facebook等海外社交平台账号，面对日语受众展现真实、立体、全面的中国形象。',
    li4P:'韩文《中国》依托中国发展的生动实践，从政治、经济、文化、社会、生态文明等多个视角，以生动感人的事例、灵动鲜活的语言，深度报道中国时事热点，权威解析经济社会发展现象，促进中韩两国的人文交流和民心相通，向韩国语读者展现真实、立体、全面的中国。',
    li5P:'中国东盟报道是聚焦中国—东盟交流与合作、关注东南亚区域发展的国家级媒体矩阵，以期刊、网站、境内外社交媒体等渠道对外传播，以多语种（英文为主，辅以东盟各国语言）出版发行，现已覆盖全体东盟国家，是面向东盟讲述中国故事的重要平台。',
    li6P:'《中国报道》（世界语）杂志创刊于1950年5月，先后发行到150多个国家和地区，被读者誉为“最精美的世界语刊物”，是我国对外传播的特殊阵地。2000年，中国报道世界语网络版上线，向世界190多个国家和地区提供有关中国经济、社会、生活等方面的重要资讯。',
    foot1:'关于我们',
    foot2:'联系我们',
    foot3:'广告发布',
    foot4:'法律顾问',
    foot5:'版权声明',
    footP1:'版权所有 中国外文局亚太传播中心   互联网新闻信息服务许可证：10120190016   互联网出版许可证：新出网证（京）字 189号   广播电视节目制作经营许可证:(京）字第07311号   京ICP备14043293号 京公网安备：110102000508',
    footP2:'电子邮件: cicgap@foxmail.com    法律顾问:北京市善邦律师事务所 贾敬伟 施晓群 地址：北京市西城区百万庄大街24号 电话: 010-68995855/68995939 ',
    titleH5:'亚太传播中心',
    herf:'点击跳转',
    Fp1:'版权所有 中国外文局亚太传播中心   互联网新闻信息服务许可证：10120190016',
    Fp2:'互联网出版许可证：新出网证（京）字 189号   广播电视节目制作经营许可证:(京）字第07311号   京ICP备14043293号 京公网安备：110102000508',
    Fp3:'电子邮件: cicgap@foxmail.com    法律顾问:北京市善邦律师事务所 贾敬伟 施晓群',
    Fp4:'地址：北京市西城区百万庄大街24号 电话: 010-68995855/68995939',
    DetailsP1:"<p style='text-indent: 2em'>中国外文局亚太传播中心（CICG Asia-Pacific），是面向亚太地区的具有媒体属性的国际传播综合体。由成立于1950年的人民中国杂志社、中国报道杂志社的全部传播业务及北京周报社日文业务、人民画报社韩文业务整合组建而成。代管中华全国世界语协会。中心的主要职能是：面向亚太地区出版发行《人民中国》（日文）、《中国报道》（中文）、《中国东盟报道》（英文）、《中国》（韩文）等多语种国际化精品期刊，运营中心所属中文、日文、英文、韩文、世界语等多语种网站和国内外主流社交媒体账号，制作多语种音视频产品，主办或承办“北京-东京论坛”等大型论坛、“熊猫杯”“悟空杯”等国家间文化交流活动。管理运营东京分社、曼谷分社等驻外机构。</p>",
    DetailsP2:`
    电话: 010-68995855  68995939<br>
    电子邮件: cicgap@foxmail.com<br>  
    地址：北京市西城区百万庄大街24号<br>
    `,
    DetailsP3:'商务电话：010-68995855',
    DetailsP4:`北京市善邦律师事务所
    <div style="margin-top: 20px;">贾敬伟 施晓群</div>`,
    DetailsP5:`<p style='text-indent: 2em'>中国外文局亚太传播中心旗下网页所涉及的任何资料(包括但不限于文字报导、图片、声音、录像、图表、广告、域名、软件、程序、版面设计、专栏目录与名称、内容分类标准)的版权均属中国外文局亚太传播中心和资料提供者所有。未经中国外文局亚太传播中心书面许可，任何人不得引用、复制、转载、摘编或以其他任何方式非法使用中国外文局亚太传播中心网站的上述内容。对于有上述行为者，中国外文局亚太传播中心将保留追究其法律责任的权利。<p>

    <p style='text-indent: 2em'>免责声明</p>
    
    <p style='text-indent: 2em'>1、凡本网注明“来源：中国外文局亚太传播中心”或“中国外文局亚太传播中心 ** 电/讯”或带有中国外文局亚太传播中心LOGO、水印的所有文字、图片和音频视频稿件，版权均属中国外文局亚太传播中心所有，任何媒体、网站或单位和个人未经本网书面授权不得转载、链接、转贴或以其他方式复制发表。已经本网书面授权的媒体、网站，在下载使用时必须注明“稿件来源：中国外文局亚太传播中心”，否则，中国外文局亚太传播中心将依法追究其法律责任。</p>
    
    <p style='text-indent: 2em'>2、对于中国外文局亚太传播中心以及所属报刊通过本网发布的作品，本网受著作权人委托禁止任何媒体、网站或个人在未经书面授权的情况下转载使用。</p>
    
    <p style='text-indent: 2em'>3、本网未标有“来源：中国外文局亚太传播中心”或“中国外文局亚太传播中心电/讯”或带有中国外文局亚太传播中心LOGO、水印的文字、图片、音频视频等稿件均为转载稿，本网转载出于传递更多信息之目的，并不意味着赞同其观点或认可其内容的真实性。如其他媒体、网站或个人从本网下载使用，必须保留本网注明的“稿件来源”，并自负版权等法律责任。如擅自篡改为“稿件来源：中国外文局亚太传播中心”，本网将依法追究责任。如本网转载稿涉及版权等问题，请著作权人来电、来函与中国外文局亚太传播中心联系。联系电话：+8610-68995855 。</p>
    
    <p style='text-indent: 2em'>4、擅自使用中国外文局亚太传播中心名义转载不规范来源的信息、版权不明的资讯，或盗用中国外文局亚太传播中心名义发布信息，本网将依法追究其法律责任。</p>
    
    <p style='text-indent: 2em'>5、本网站如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本网站控制范围外的硬件故障或其它不可抗力而导致暂停服务，对于暂停服务期间造成的一切不便与损失，本网站不负任何责任。</p>
    
    <p style='text-indent: 2em'>6、鉴于本网发布稿件来源广泛、数量较多，如因作者联系方式不详或其它原因未能与著作权拥有者取得联系，著作权人发现本网转载了其拥有著作权的作品时，请主动与本网联系，提供相关证明材料，我网将及时处理。联系电话：68995855</p>
    
    <p style='text-indent: 2em'>7、本网站任何员工及合作单位不得擅自使用中国外文局亚太传播中心名义开展对外合作、冠名、主办、协办等商业活动;未经授权允许的任何对外合作，我网不予承认，并将追究违规单位或个人的法律责任。</p>
    
    <p style='text-indent: 2em'>本网站之声明以及其修改权、更新权及最终解释权均属中国外文局亚太传播中心所有。</p>
    
    <p style='text-indent: 2em'>新闻资讯合作</p>
    
    <p style='text-indent: 2em'>为尊重作者的著作权和信息网络传播权，促进互联网良性发展，本网站本着平等互惠、资源共享的原则，诚邀各类媒体与我们建立友好合作关系，具体合作事宜参见供稿服务或来电咨询：+8610-68995855。 </p>
    `,
}

    